import { buildSearchQuery, isBuyNowQuery } from '@/utils/search/searchQuery';
import { DataLoaderParams } from '@/types/DataLoaderParams';
import { fetchCatalogAdIfNeeded } from '@/redux/modules/catalog/ad/catalogAd.actions';
import { fetchItemFacetsIfNeeded, getItemFacets } from '@/redux/modules/itemFacets';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchSearchFacetsIfNeeded } from '@/redux/modules/searchFacets';
import { fetchSearchSuggestions, getSecondarySearchSuggestions } from '@/redux/modules/searchSuggestions';
import { fetchSellersIfNeeded } from '@/redux/modules/seller';
import { fetchSimilarItemsIfNeeded, getSimilarItemIds } from '@/redux/modules/similarItems';
import { fetchWonItemsForBannerIfNeeded } from '@/redux/modules/wonItems/wonItems.actions';
import {
    getCategoryIdsFromQueryParams,
    getKeywordFromQueryParams,
    isArchivedSearch,
    parseQueryParams,
} from '@/utils/queryParams';
import { getFacetIds } from '@/utils/ItemPageUtils';
import { getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import {
    getRawArchivedSearchFacets,
    getRawUpcomingSearchFacets,
} from '@/redux/modules/searchFacetAggregates.selectors';
import { getSearchedItemIds, getSearchUiState } from '@/redux/modules/search/search.selectors';
import { getSearchPageSize } from '@/redux/modules/pagination';
import { submitSearch } from '@/redux/modules/search/search.actions';
import cleanSearchTerms from '@/utils/cleanSearchTerms';

export default ({ featureFlagVariants, location, routerState, store }: DataLoaderParams) => {
    const { dispatch, getState } = store;
    const queryParams = parseQueryParams(location.search);
    const { pathname } = location;

    const archivedSearch = isArchivedSearch(queryParams, pathname);

    return Promise.all([dispatch(fetchSearchFacetsIfNeeded(archivedSearch))]).then(async () => {
        const currentState = getState();

        // Experiment.PriceResultSimilarItemsCTA, default variant
        const isSimilarItemsVariant = false;

        const pageSize = getSearchPageSize(currentState);
        let rawFacets;
        if (archivedSearch) {
            rawFacets = getRawArchivedSearchFacets(currentState);
        } else {
            rawFacets = getRawUpcomingSearchFacets(currentState);
        }
        const searchQuery = buildSearchQuery({
            pathname,
            queryParams,
            rawFacets,
            searchPageSize: pageSize,
            similarItems: isSimilarItemsVariant,
        });
        const isBIN = isBuyNowQuery(searchQuery);
        searchQuery.buyNow = !archivedSearch && isBIN;
        const { searchTerm } = searchQuery;
        let pageName = archivedSearch ? 'sold-search' : 'search';

        if (pathname === '/search-results/') {
            pageName = 'seo-search';
        }
        const keywords = getKeywordFromQueryParams(queryParams);

        const promises = [
            dispatch(submitSearch({ pageName, routerState, searchQuery })),
            dispatch(fetchSearchSuggestions(cleanSearchTerms(searchTerm), true)),
            dispatch(fetchWonItemsForBannerIfNeeded({ featureFlagVariants })),
        ];

        await Promise.all(promises);

        const searchState = getState();
        const itemIds = getSearchedItemIds(searchState);

        // If we have search results do all the stuff to get the data for them.
        if (itemIds.length) {
            const { houses } = getSecondarySearchSuggestions(searchState);

            const promises: Promise<any>[] = [
                dispatch(
                    fetchCatalogAdIfNeeded({
                        categoryIds: getCategoryIdsFromQueryParams(queryParams),
                        keywords: `${keywords}`,
                        pageName,
                    })
                ),
            ];

            if (Boolean(houses?.[0]?.houseId)) {
                promises.push(dispatch(fetchSellersIfNeeded([houses[0]?.houseId])));
            }

            return Promise.all(promises);

            // If we get no results back, we'll get the data for our zero results page
        } else {
            const { soldItemIds = [] } = getSearchUiState(searchState);
            const promises = [
                dispatch(
                    fetchItemSummariesIfNeeded({
                        identifier: 'SEARCH-PAGE-SOLD',
                        itemIds: soldItemIds,
                        similarItems: true,
                    })
                ),
                dispatch(fetchItemFacetsIfNeeded([soldItemIds[0]])),
            ];

            return Promise.all(promises).then(() => {
                const state = getState();
                const { itemId, sellerId, title } = getItemSummary(state, soldItemIds[0]);
                const facets = getItemFacets(state, itemId);
                const categoryIds = getFacetIds(facets);
                return Promise.all([
                    dispatch(
                        fetchSimilarItemsIfNeeded({
                            categoryIds,
                            itemId,
                            sellerId,
                            title,
                        })
                    ),
                ]).then(() => {
                    const state = getState();
                    const similarItemIds = getSimilarItemIds(state, itemId);
                    return dispatch(
                        fetchItemSummariesIfNeeded({
                            identifier: 'SEARCH-PAGE-SIMILAR',
                            itemIds: similarItemIds,
                        })
                    );
                });
            });
        }
    });
};
